<template>
  <div class="row h-100vh m-0 d-flex justify-content-center align-items-center">
    <button
      v-if="!isOption"
      class="h-10 w-100px rounded-5"
      @click.prevent="onClick">
      Klik
    </button>
    <div v-if="isOption" class="row h-100vh m-0 p-0">
      <div class="col-12 col-sm-6 col-md-6 m-0 p-0 d-flex justify-content-center align-items-center">
        <div class="row d-flex justify-content-center">
          <img :src="img.foto" class="col-12 h-30vh w-auto c-pointer" @click.prevent="handleFoto"/>
          <div class="col-12"><b>FOTO</b></div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-6 m-0 p-0 d-flex justify-content-center align-items-center">
        <div class="row d-flex justify-content-center">
          <img :src="img.video" class="col-12 h-30vh w-auto c-pointer"/>
          <div class="col-12"><b>VIDEO</b></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import foto from '../../assets/foto.png'
import video from '../../assets/video.png'

export default {
  name: 'Home',
  data () {
    return {
      isOption: false,
      img: {
        foto: foto,
        video: video
      }
    }
  },
  methods: {
    onClick () {
      this.isOption = true
    },
    handleFoto () {
      this.$router.replace({
        name: 'Foto'
      })
    }
  }
}
</script>
